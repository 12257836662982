import React from "react";

const Footer = () => {
  return (
    <div className="divFooter">
      Powered by © Liguria Consulting Solutions Srl |{" "}
      <a href=" http://www.itlcs.com " target="_blank">
        www.itlcs.com
      </a>{" "}
      |{" "}
      <a className="link" href="mailto:assistenza@lc-sw.com?subject=Assistenza">
        assistenza@lc-sw.com
      </a>
      &nbsp;&nbsp;&nbsp;Versione 1.0.0
    </div>
  );
};

export default Footer;
