import React from "react";
import { useLocation } from "react-router-dom";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";

export interface HeaderCurrentPageProps {
  customMessage?: string;
}

const HeaderCurrentPage = (props: HeaderCurrentPageProps) => {
  let location = useLocation();
              
  const getPathName = () => {
    var pathName = location.pathname;

    while (pathName.charAt(0) === "/") {
      pathName = pathName.substring(1);
    }

    pathName = (pathName === "general")
      ? (pathName = i18next.t("GeneralData", { ns: CUSTOM_PRJ_TRANSLATION_FILE }))
      : pathName;

    return <strong>{i18next.t(pathName.charAt(0).toUpperCase() + pathName.slice(1), { ns: CUSTOM_PRJ_TRANSLATION_FILE })}</strong>;
                 
  }
    
  return(
      <div className='headerCurrent'>
          <label className='currentPageTitle'>{
          getPathName()          
          }</label>
          <label>
            {props.customMessage}
          </label>
      </div>
  );
};

export default HeaderCurrentPage;
