import React, { useState } from "react";
import i18next from 'i18next';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { handleError } from "./utils/Common";
import { IAuthService, LoginRequest, LoginResponse } from "./services/auth.service";
import TokenService from "./services/token.service";
import CardComponent from "./components/Card";
import "./style/Login.css";

export interface LoginProps {
  onSuccessfulLogin: any;
  authService: IAuthService;
}

function Login(props: LoginProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  let onChangeUsername = (e: any) => {
    setUsername(e.target.value);
  };

  let onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };
  //TODO: REFACTORING this
  let onOkClick = () => {
    validateCredentials();
  };

  let onCancelClick = () => {
    setUsername('');
    setPassword('');
  };

  let onLoginResponse = async (response: LoginResponse) => {
    if (response && response.success) {
      const user = response;
      await props.onSuccessfulLogin(user);
    } else {
      if (response && !response.success) {
        console.log(response.reason);
      }
      setError(true);
    }
  };

  let validateCredentials = async () => {
    try {
      const request : LoginRequest = {
        username: username,
        password: password,
      };
      await props.authService.login(request)
        .then(onLoginResponse)
        .catch(function (error) {
          setError(true);
        });
    } catch (e) {
      handleError("validateCredentials", e);
      TokenService.removeUser();
    }
  };
  const inputForm = (<><label>{i18next.t("Username")}</label><InputText
  className="inputfield w-full"
  autoFocus
  type="text"
  value={username}
  onChange={onChangeUsername} /><label>{i18next.t("Password")}</label><InputText
    className="inputfield w-full"
    type="password"
    value={password}
    onChange={onChangePassword}
    onKeyPress={(e) => (e.key === "Enter" ? validateCredentials() : null)} /><div className="text-center" style={{ marginTop: "20px" }}>
    <Button
      className="p-button-success"
      icon="pi pi-check"
      label={i18next.t("OK")}
      onClick={onOkClick} />
    <Button
      className="p-button-secondary"
      icon="pi pi-replay"
      label={i18next.t("Reset")}
      onClick={onCancelClick} />
  </div></>);
    
      

  return (
    <div className="containerLogin">
      <CardComponent
        footer={
          error ? (
            <div style={{ color: "red" }}>
              {i18next.t("AuthenticationFailed")}
            </div>
          ) : null
        }
        text={inputForm}
        header=''
      />
    </div>
  );
}

export default Login;
