export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_CUSTOMER = 'ROLE_CUSTOMER';
export const ROLE_SUPERVISOR = 'ROLE_SUPERVISOR';

export enum ROUTE  {
    LOGIN = '/login',
    ADMINISTRATION = '/administration',
    GENERAL = '/general',
    SETTINGS = '/settings',
    WORKPLAN = '/workplan',
    SUPERVISOR = '/supervisor',
    APPOINTMENTS = '/appointments',
    DASHBOARDS = '/dashboards'
}
