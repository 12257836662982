import _locales_en_common from './locales/en/common.json';
import _locales_it_common from './locales/it/common.json';

const locales_en_common = _locales_en_common;
const locales_it_common = _locales_it_common;

export { default as Configuration } from "./configuration";
export { default as ConfigurationPanelContent } from './configuration/configuration-panel/configuration-panel-content/ConfigurationPanelContent'
export { default as TokenService, User } from "./services";
export { default as Login} from "./Login";
export { setComponentsCulture } from "./utils/Common";
export { default as Footer} from "./Footer";
export { default as Header, HeaderProps, ItemMenu } from "./Header";
export { default as ConfigurationService, IConfigurationService, ConfigurationServiceProps, ConfigurationServiceSourceType } from './services/configuration.service';
export { default as CustomService, ICustomService, ICustomServiceResponse } from './services/custom.service';
export { default as AuthService, IAuthService, LoginRequest, LoginResponse, LogoutRequest, LogoutResponse, RefreshTokenRequest, RefreshTokenResponse, ChangePasswordRequest, ChangePasswordResponse } from './services/auth.service';
export { default as PageTemplate, PageTemplateProps} from './PageTemplate';
export { default as ReportTemplate, ReportTemplateProps} from './ReportTemplate';
export { default as IDataService, IDataServiceResponse} from './services/i.data.service';
export { default as Viewer} from './configuration/viewer/Viewer';
export { EditableDataServiceResponse, EditableDataService } from './services/editable.data.service';
export { appendInterceptors } from './services/api';
export { MenuType } from './components/HeaderUserComp/HeaderUserComp'
export { Entity, EntityField, EntityForRole } from "./configuration/ConfigurationTypes";
export { CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE } from './utils/Constants';
export { correctDate, serializeDate } from './utils/Common'
export { locales_en_common, locales_it_common };
export { default as Modale} from './components/Modale';