import React, { useEffect, useRef, useState } from "react";
import api from "../api";
import SHFullCalendar, { CalendarEvent, CalendarResource } from "../components/SHFullCalendar";
import ProblemService, { DefinedProblemResponse, LastSubmittedRequest, LastSubmittedResponse} from "../services/problem.service";
import { Dialog } from "primereact/dialog";    
import i18next from "i18next";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE, ICustomServiceResponse } from "@lcs/frontend";
import AppointmentDetail from "../components/AppointmentDetail";
import { Button } from "primereact/button";
import AppointmentService, { AppointmentIn, AppointmentOut, 
    AppointmentServiceResponse, AppointmentServiceSingleResponse, APPOINTMENT_STATE, CloseAppointmentRequest } from "../services/appointment.service";
import { Toast } from "primereact/toast";
import EmployeeService, { EmployeeOut, EmployeeServiceResponse } from "../services/employee.service";
import AbsenceService, { AbsenceOut, AbsenceServiceResponse } from "../services/absence.service";
import AppointmentClosure from "../components/AppointmentClosure";
import { calculateAppointmentEndDate } from "../utils/Common";
import WorkingTimeService, { WorkingTimeServiceResponse } from "../services/workingTime.service";
import ColorLegend from "../components/ColorLegend/ColorLegend";

export interface EventExtendedProps{    
    luogoAppuntamento?: string;
    indirizzo?: string;
    citta?: string;
    provincia?: string;
    cliente?: string;
    tipoCliente?: string;
    durata?: string;
    specializzazione?: string;
    idDipendente?: number;
    inizio?: Date;
    idAppuntamento?: string;
    stato?: string;
    confermato?: boolean;
    denominazione?: string;  
    isAssenza?: boolean;  
    indirizzoPianificato?: string;
    cittaPianificata?: string;
    provinciaPianificata?: string;
    note?: string;
}

export interface PageWorkPlanProps {
    culture?: string;
}

function PageWorkPlan (props: PageWorkPlanProps) {
    const myToast = useRef<any>();

    const [resources, setResources] = useState<CalendarResource[]>([]);
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [eventDetailsVisible, setEventDetailsVisible] = useState<boolean>(false);
    const [chooseCloseStateVisible, setChooseCloseStateVisible] = useState<boolean>(false);    
    const [eventDetails, setEventDetails] = useState<EventExtendedProps>({});
    const [problemService] = useState<ProblemService>(new ProblemService(api));
    const [appointmentService] = useState<AppointmentService>(new AppointmentService(api));
    const [employeeService] = useState<EmployeeService>(new EmployeeService(api));
    const [workingTimeService] = useState<WorkingTimeService>(new WorkingTimeService(api));    
    const [absenceService] = useState<AbsenceService>(new AbsenceService(api));
    const [confirmingAppointment, setConfirmingAppointment] = useState<boolean>(false);
    const [closingAppointment, setClosingAppointment] = useState<boolean>(false);
    const [appointmentClosureData, setAppointmentClosureData] = useState<{state: string, note: string}>({state: "", note: ""})
  
    let redirect = () => {
    };
    
    
    const addEvent= (events: CalendarEvent[], appuntamento: AppointmentOut, minDate: Date) => {
        if (appuntamento === null || typeof(appuntamento) === "undefined") {
            return minDate;
        }        
        const extProps: EventExtendedProps = {
            luogoAppuntamento: appuntamento.luogoAppuntamento?.toString(),
            indirizzo: appuntamento.indirizzo??"",
            citta: appuntamento.citta??"",
            provincia: appuntamento.provincia??"",
            cliente: appuntamento?.idCliente?.value,
            tipoCliente: appuntamento.idTipoCliente?.value,
            durata: appuntamento.durataStimataInMezzore,
            specializzazione: appuntamento.idSpecializzazione?.value,
            idDipendente: appuntamento.idDipendentePianificato?.id,
            inizio: appuntamento.orarioPianificato? new Date(appuntamento.orarioPianificato):undefined,
            idAppuntamento: appuntamento.id.toString(),
            stato: appuntamento.stato,
            confermato: appuntamento.idDipendenteStabilito?true:false,
            denominazione: appuntamento.denominazione,
            isAssenza: false,
            indirizzoPianificato: appuntamento.indirizzoPianificato,
            cittaPianificata: appuntamento.cittaPianificata??"",
            provinciaPianificata: appuntamento.provinciaPianificata??"",
            note: appuntamento.note
        }
        let eventStart: Date = appuntamento.orarioPianificato? new Date(appuntamento.orarioPianificato):new Date();
        if (eventStart < minDate){
            minDate = eventStart;            
        }            

        let eventEnd: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataStimataInMezzore);
                        
        const calEvent: CalendarEvent = {
            id: appuntamento.id.toString(),
            title: appuntamento.denominazione??"",
            start: eventStart,
            end: eventEnd,
            resourceId: appuntamento.idDipendentePianificato?.value??"",
            extendedProps: extProps,
            color: getColorForEventState(appuntamento.stato, appuntamento.idDipendenteStabilito?true:false)
        }
        events.push(calEvent);
        return minDate;
    }

    const addAbsenceEvent = ( absence: AbsenceOut) =>  {
        const extProps: EventExtendedProps = {            
            idDipendente: absence.idDipendente.id,
            inizio: absence.da,
            idAppuntamento: absence.id.toString(),                        
            denominazione: absence.denominazione,
            isAssenza: true
        }
        const calEvent: CalendarEvent = {
            id: absence.id.toString(),
            title: absence.denominazione??"",
            start: absence.da,
            end: absence.a,
            resourceId: absence.idDipendente?.value??"",
            extendedProps: extProps,
            color: "grey"
        }
        return calEvent;
    }

    const getColorForEventState = (state: APPOINTMENT_STATE | undefined, isConfermato: boolean): string => {
        if (state){
            let eventColor: string = "gray";
            switch (state)
            {
                case APPOINTMENT_STATE.PIANIFICATO:
                    eventColor = "Green";
                    if (isConfermato)
                        eventColor = "GoldenRod";
                    break;
                case APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO:
                    eventColor = "Purple";
                    break;
                case APPOINTMENT_STATE.CHIUSO_SUCCESSO:
                    eventColor = "LightGray";
                    break;
                case APPOINTMENT_STATE.CHIUSO_FALLIMENTO:
                    eventColor = "DarkRed"; 
                    break;
                default: 
                    eventColor = "gray";
            }
            return eventColor;
        }            
        else
            return "WhiteSmoke";
    }

    const  addEntryToEvents = (entry: CalendarEvent) => {
        setEvents(old => [...old, entry]);
    };

    const  addEntryToResources = (entry: CalendarResource) => {
        setResources(old => [...old, entry]);
    };

    const loadProblemDefinition = (): Promise<Date> => {
        let probStartDate: Date = new Date();
        return new Promise<Date>((resolve, reject) =>{ 
            problemService.getDefinedProblem()
            .then((probResp: DefinedProblemResponse) => {
            
                probStartDate = new Date(probResp.data[0].startDate);
                probStartDate.setDate(probStartDate.getDate() + probResp.data[0].planningDays);
                setEndDate(probStartDate);
                resolve(probStartDate);
            });
        });          
    }

    const loadData = async (windowEndDate: Date) => {     
        setEvents([]);

        const lsubReq: LastSubmittedRequest = {
            problemName: "Problema Unico"
        }
        problemService.getLastSubmittedProblem(lsubReq)
        .then((lsubRes:LastSubmittedResponse) => {
            if (!lsubRes.success){
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                    detail: lsubRes.reason
                  });
                return;
            }
            
            appointmentService.getAll().then((res:AppointmentServiceResponse) => {
                if (res.success){                    
                    let minDate: Date = new Date();
                    let tempEvents : CalendarEvent[] = [];
                    res.data.forEach((appuntamento: AppointmentOut) => {
                        let eventStart: Date = appuntamento.orarioPianificato? new Date(appuntamento.orarioPianificato):new Date();
                        let eventEnd: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataStimataInMezzore);                        
                        if (eventEnd <= windowEndDate)
                            minDate = addEvent(tempEvents, appuntamento, minDate);
                    });
                    setStartDate(minDate);                   
                    tempEvents.map(addEntryToEvents);
                }else{
                    myToast.current.show({
                        severity: 'error',
                        summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: res.reason
                      });
                }
            });
                            
            employeeService.getAllMyCollegues().then((result: EmployeeServiceResponse) => {
                if (result.success){  
                    let ress: CalendarResource[] = [];                      
                    result.data.forEach((dip: EmployeeOut) => {
                        workingTimeService.getById(dip.idOrarioGiornaliero?dip.idOrarioGiornaliero.id:0)
                        .then((wt: WorkingTimeServiceResponse) => {
                            addEntryToResources( {                          
                                id: dip.denominazione,
                                title: dip.denominazione,
                                eventColor: "green",
                                businessHours: {
                                    startTime: wt.data.inizioTurno,
                                    endTime: wt.data.fineTurno
                                }
                            });
                        });                        
                    });
                    //setResources(ress);                                        
                    result.data.forEach(emp => {
                        absenceService.getByEmployeeId(emp.id).then((result : AbsenceServiceResponse) => {
                            if (result.success){
                                result.data.forEach( (abs: AbsenceOut) => {
                                    let absenceEvents : CalendarEvent = addAbsenceEvent(abs);
                                    addEntryToEvents(absenceEvents);
                                });                                                               
                            }
                            else{
                                myToast.current.show({
                                    severity: 'error',
                                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                                    detail: result.reason
                                  });
                            }
                        });                        
                    });                                        
                }else{
                    myToast.current.show({
                        severity: 'error',
                        summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: result.reason
                      });
                }                
            });                           
        });
    }

    useEffect(() => {        
        redirect();
        loadProblemDefinition().then((windowEndDate: Date) => {
            loadData(windowEndDate);
        });        
    },[]); // TODO: check dependencies

    
    const eventClick = (arg: any) => {
        if (!arg.event.extendedProps.isAssenza){
            setEventDetails(arg.event.extendedProps);
            setClosingAppointment(false);
            setAppointmentClosureData({state:"", note:""})
            setEventDetailsVisible(true);            
        }        
    }


    const closeAppointment = () => {
        setClosingAppointment(true)
        if (!eventDetails.idAppuntamento){            
            myToast.current.show({
                severity: 'error',
                summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                detail: i18next.t("EmptyAppointment", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
              });
            setClosingAppointment(false);
            return;
        }
        appointmentService.getById(eventDetails.idAppuntamento).then((res:AppointmentServiceSingleResponse) => {
            if (!res.success){
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                    detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                  });
                setClosingAppointment(false);
                return;
            }
            let appCReq: CloseAppointmentRequest = {
                appointmentId: res.data.id,
                status: APPOINTMENT_STATE[appointmentClosureData.state as keyof typeof APPOINTMENT_STATE],
                note: appointmentClosureData.note
            }
            appointmentService.close(appCReq).then((result: ICustomServiceResponse) => {
                if (result.success){
                    myToast.current.show({
                        severity: 'success',
                        summary: i18next.t('Success', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: i18next.t(result.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                      });
                      loadData(endDate);
                      setAppointmentClosureData({state:"", note:""});
                      setEventDetailsVisible(false);
                      setClosingAppointment(false);
                } else {
                    myToast.current.show({
                        severity: 'error',
                        summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: i18next.t(result.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                      });
                }
            });
        });
        
    }   

    const openCloseAppointmentDialog = () => {    
        setChooseCloseStateVisible(true);
    }

    const confirmAppointment = () => {
        setConfirmingAppointment(true);
        if (!eventDetails.idAppuntamento){            
            myToast.current.show({
                severity: 'error',
                summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                detail: i18next.t("EmptyAppointment", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
              });
            setConfirmingAppointment(false);
            return;
        }

        appointmentService.getById(eventDetails.idAppuntamento).then((res:AppointmentServiceSingleResponse) => {
            if (!res.success){
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                    detail: i18next.t(res.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                  });
                setConfirmingAppointment(false);
                return;
            }
            if (res.data.idDipendenteStabilito != null){
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                    detail: i18next.t('ALREADY_CONFIRMED', { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                  });
                setConfirmingAppointment(false);
                return;
            }
            let appData: AppointmentIn = res.data;
            appData.idDipendenteStabilito = eventDetails.idDipendente;
            appData.orarioStabilito = eventDetails.inizio?.toISOString();
            appointmentService.comfirm(appData).then((result: ICustomServiceResponse) => {
                if (result.success){
                    myToast.current.show({
                        severity: 'success',
                        summary: i18next.t('Success', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: i18next.t(result.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                      });
                      loadData(endDate);
                      setEventDetailsVisible(false);
                } else {
                    myToast.current.show({
                        severity: 'error',
                        summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                        detail: i18next.t(result.reason, { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                      });
                }
            });
        });
        setConfirmingAppointment(false);        
    }
   
    return (
        <div style={{margin:"10px"}}>
          <Toast ref={myToast} />
           <SHFullCalendar 
            type="TimeLine"
            resources={resources}
            disableBeforeDate={startDate}
            events={events}
            eventClick={eventClick}
            culture={props.culture}
            colorLegendItems={
                [
                    {
                        color: getColorForEventState(APPOINTMENT_STATE.PIANIFICATO, false),
                        label: i18next.t(APPOINTMENT_STATE.PIANIFICATO,{ ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    },
                    {
                        color: getColorForEventState(APPOINTMENT_STATE.PIANIFICATO, true),
                        label: i18next.t("CONFERMATO",{ ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    },
                    {
                        color: getColorForEventState(APPOINTMENT_STATE.CHIUSO_SUCCESSO, false),
                        label: i18next.t(APPOINTMENT_STATE.CHIUSO_SUCCESSO,{ ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    },
                    {
                        color: getColorForEventState(APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO, false),
                        label: i18next.t(APPOINTMENT_STATE.CHIUSO_INTERLOCUTORIO,{ ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    },
                    {
                        color: getColorForEventState(APPOINTMENT_STATE.CHIUSO_FALLIMENTO, false),
                        label: i18next.t(APPOINTMENT_STATE.CHIUSO_FALLIMENTO,{ ns: CUSTOM_PRJ_TRANSLATION_FILE })
                    }
                ]
            }
          />          
           <Dialog 
            header= {eventDetails.denominazione}
            visible={eventDetailsVisible}
            onHide= {() => setEventDetailsVisible(false)}
            className="eventDetailsDialog"
            // resizable={true}
            // maximizable={true}
            >
                <Dialog 
                header= {i18next.t("AppointmentClosure", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
                visible={chooseCloseStateVisible}
                onHide= {() => setChooseCloseStateVisible(false)}
                footer= {<div>
                        <Button 
                        label={i18next.t("OK", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}
                        onClick={closeAppointment}                    
                        loading={closingAppointment}
                        />
                        <Button 
                            label={i18next.t("Cancel", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}
                            onClick={() => {
                                setChooseCloseStateVisible(false);
                                setEventDetailsVisible(true);
                            }}                        
                        />
                        </div>
                    } 
                className="AppointmentClosureDialog"
                >
                <AppointmentClosure eventDetails={eventDetails}
                                    setNote={(newNote: string) => setAppointmentClosureData((old) =>{ return {state: old.state, note: newNote}})} 
                                    setState={(newState: string) => setAppointmentClosureData((old) =>{ return {state: newState, note: old.note}})}
                />
            </Dialog>
            <AppointmentDetail eventDetails={eventDetails} 
                               onCloseApp={openCloseAppointmentDialog}
                               onConfirmApp={confirmAppointment}
                               confirmingAppointment={confirmingAppointment}
                               closingAppointment={closingAppointment}/>
          </Dialog>
        </div>
        
    );
}

export default PageWorkPlan;