import { setComponentsCulture, TokenService } from "@lcs/frontend";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import i18next from "i18next";
import { defaultLanguage } from "../i18n";
import { ROUTE } from "../utils/Constants";


export const shAppendInterceptors = (i : AxiosInstance) : void => {
  
  const appendAuthorizationHeader = (config: AxiosRequestConfig) : AxiosRequestConfig => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  }

  i.interceptors.request.use(appendAuthorizationHeader);

  i.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 403) {
        try{
          TokenService.removeUser();
        } catch (error){
          localStorage.removeItem("user");
        }
        i18next.changeLanguage(defaultLanguage);
        i18next.changeLanguage(defaultLanguage);
        setComponentsCulture(defaultLanguage);
        window.location.href = ROUTE.LOGIN;
      }
    });
}
