import React, { LegacyRef, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import engbLocale from '@fullcalendar/core/locales/en-gb';
import itLocale from '@fullcalendar/core/locales/it'; // Bug da riportare: non traduce "Resources" sulla resourceTimeline
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

import i18next from 'i18next';

// Premium license required
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import { CUSTOM_PRJ_TRANSLATION_FILE } from '@lcs/frontend';
import { Dialog } from 'primereact/dialog';
import ColorLegend, { ColorLegendItem } from './ColorLegend/ColorLegend';


export interface BusinessHours {
  startTime: string,
  endTime: string
}

export interface CalendarResource {
  id: string,
  title: string,
  eventColor?: string,
  businessHours: BusinessHours
}

export interface CalendarEvent {
  id: string,
  title: string,
  start: Date,
  end?: Date,
  resourceId: string,
  extendedProps?: any,
  color?: string,
  display?: string
}

export interface SHFullCalendarProps{
  type: string;
  switchView?: () => void;
  resources?: CalendarResource[]; // ResourceSourceInput
  disableBeforeDate?: any; // DateInput
  events?: CalendarEvent[]; // EventSourceInput
  dateClick?: (arg: any) => void;
  eventClick?: (e: any) => void;
  culture?: string;
  colorLegendItems?: ColorLegendItem[];
}

function SHFullCalendar (props: SHFullCalendarProps)  {

  const [currentType, setCurrentType] = useState<string>("Calendar");
  const [currentResourceView, setCurrentResourceView] = useState<string>("resourceTimeGrid");
  const [colorLegendVisible, setColorLegendVisible] = useState<boolean>(false);

  const calendarRef: LegacyRef<FullCalendar> = React.createRef();

  useEffect(() => {  
    const setView = () => {
      if (calendarRef.current && (currentType !== props.type || props.type === 'TimeLine')){
      calendarRef.current.getApi()
      .changeView(props.type === 'TimeLine'? currentResourceView+'Week': 'dayGridMonth')
      };    
    }

    setView();
    setCurrentType(props.type);
  }, [props.type, calendarRef, currentType, props.disableBeforeDate, currentResourceView]);
 
  return (
    <div>
      <FullCalendar
        schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
        
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,resourceTimelinePlugin,resourceTimeGridPlugin]}
        locales={ [itLocale, engbLocale]}
        locale={ props.culture && props.culture === 'it-IT' ? 'it' : 'en-gb'} //Per ora supportiamo solo inglese e italiano
        selectable={true}
        customButtons={
        (props.type === 'TimeLine' && currentResourceView.startsWith('resourceTime'))?
        {
          switchResourceButton: {
              text: i18next.t("SwitchResourceView", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
              click: function() {                
                if (currentResourceView === 'resourceTimeline')
                  setCurrentResourceView('resourceTimeGrid')
                else
                  setCurrentResourceView('resourceTimeline')
              },
          },
          legendButton: {
            text: i18next.t("i", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
            click: function() {                
              setColorLegendVisible(true);
            },            
        },
        }:
        undefined
        }          
        headerToolbar={ (props.switchView)?{
          left: 'prev,next,today, SwitchView',
          center: 'title',
          right: props.type==='Calendar'?'dayGridMonth,timeGridWeek,timeGridDay'
                  : currentResourceView + 'Day,'+ currentResourceView + 'Week,'+ currentResourceView + 'Month'
        }:
        {
          left: (currentResourceView === 'resourceTimeline' || currentResourceView === 'resourceTimeGrid')? 'today switchResourceButton legendButton': 'today',
          center: 'title',
          right: props.type==='Calendar'?'dayGridMonth,timeGridWeek,timeGridDay,prev,next'
                  : 
                  (currentResourceView === 'resourceTimeline')?
                  currentResourceView + 'Day,'+ currentResourceView + 'Week,' + currentResourceView + 'Month,prev,next'
                  :
                  currentResourceView + 'Day,'+ currentResourceView + 'Week,prev,next'
        }
        }
        businessHours={true}
        //editable={true}
        initialDate={props.disableBeforeDate}
        initialView= {props.type === 'TimeLine'? currentResourceView+'Day': undefined}
        height= {550}
        resources={props.resources}
        events={props.events}
        validRange={{
          start: props.disableBeforeDate
        }}
        eventTimeFormat={ {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false
        }}
        //slotDuration="00:30:00"
        resourceAreaWidth="15%"
        dateClick={props.dateClick}
        eventClick={props.eventClick}
        ref={calendarRef}
      />
      <Dialog 
        header= {i18next.t("Legend", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
        visible={colorLegendVisible}
        onHide= {() => setColorLegendVisible(false)}
        className="colorLegendDialog"
      >
        {props.colorLegendItems? <ColorLegend items={props.colorLegendItems}></ColorLegend>: ""}
      </Dialog>
      </div>
    )
  }


export default SHFullCalendar;