import React from "react";
import './ColorLegend.css';

export interface ColorLegendItem{
    color: string;
    label: string;
}

export interface ColorLegendProps{
    items: ColorLegendItem[];
}

const ColorLegend = (props: ColorLegendProps) => {

    return (        
        <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            <ul className="Legend">
            {
                props.items.map((i: ColorLegendItem) => 
                <li>
                    <span className="Legend-colorBox" style={{backgroundColor: i.color}}>
                    </span>
                    <span className="Legend-label">
                        {i.label}
                    </span>
                </li>
                )
            }
            </ul>
        </div>                   
    );
}

export default ColorLegend;