import React from "react";
import i18next from "i18next";
import { CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";


export interface AppointmentPropertyProps{
    label: string;
    content?: string;
}

const AppointmentProperty = (props: AppointmentPropertyProps) => {

    return (        
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
            <label className="labelAppointment" style={{ color: "#007ad9" }}>
                {i18next.t(props.label, { ns: CUSTOM_PRJ_TRANSLATION_FILE })}: 
            </label>
            <label className="labelAppointment">
                {props.content}
            </label>
            </div>                   
    );
}

export default AppointmentProperty;