import { Configuration, IConfigurationService, ICustomService, 
  ConfigurationService, ConfigurationServiceSourceType, TokenService, User, 
  CustomService} from "@lcs/frontend";
import React from "react";
import api from '../api';

function PageSettings () {
    const configurationService : IConfigurationService = new ConfigurationService({
        entityDefinitionSource: ConfigurationServiceSourceType.axios,
        axios: api,
        entityDefinitionJsonFilePath: null,
        entityRoleJsonFilePath: null
      });
    const customService: ICustomService = new CustomService(api);
    const getUserLocal = () : User | null => {
      var currentUser: User | null = {
        id: 0,
        name: "",
        surname: "",
        language: "",
        roles: []
      }
      try {
        currentUser = TokenService.getUser()
      } catch (error) {
        
      }
      return currentUser;
    }
    let currentUser: User | null = getUserLocal();

    return (
      <div style={{margin:"5px"}}>
      <Configuration
        configurationService={configurationService}
        customService={customService}
        instance={"Impostazioni"}
        colorHeaderPdf="#aa0b0a"
        culture={currentUser ? currentUser.language : "en-US"}
        filterHeader=""
      />
      </div>
    );
       
   
}

export default PageSettings;