export const calculateAppointmentEndDate = (start: Date, durataStimataInMezzore: string | undefined) : Date => {
    let eventEnd: Date = new Date(start);
    const hours: string | undefined = durataStimataInMezzore?.substring(0,2);
    const minutes: string | undefined = durataStimataInMezzore?.substring(3,5);
    
    let nMinutes: number = 0;
    if (hours && minutes){
        const nHours: number = parseInt(hours);
        nMinutes = parseInt(minutes) + nHours * 60;
    }
    eventEnd.setMinutes(start.getMinutes() + nMinutes);
    return eventEnd;
}