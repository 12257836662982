import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import i18next from "i18next";
import { defaultLanguage } from './i18n';
import {
  MenuType, ItemMenu, Header, TokenService, User,
  IAuthService, CUSTOM_PRJ_TRANSLATION_FILE, setComponentsCulture
} from "@lcs/frontend";
import { ROUTE } from "./utils/Constants"
import './App.css';
import "primereact/resources/themes/nova-alt/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import PageGeneralData from './pages/PageGeneralData';
import PageLogin from "./pages/PageLogin";
import PageHome from "./pages/PageHome";
import HeaderCurrentPage from "./components/HeaderCurrentPage";
import SmartHelperAuthService from "./services/smarthelper.auth.service";
import api from "./api";
import { shAppendInterceptors } from "./services/api";
import PageWorkPlan from "./pages/PageWorkPlan";
import PageAppointments from "./pages/PageAppointments";
import PageSettings from "./pages/PageSettings";

function App() {
  
  const authService: IAuthService = new SmartHelperAuthService(api);
  const navigate = useNavigate();
  const location = useLocation();
  let menuList: { [id: string]: number } = {};

  shAppendInterceptors(api);

  const getUserLocal = () : User | null => {
    var currentUser: User | null = {
      id: 0,
      name: "",
      surname: "",
      language: "",
      roles: []
    }
    try {
      currentUser = TokenService.getUser()
    } catch (error) {
      localStorage.setItem("user", JSON.stringify(currentUser));
    }
    return currentUser;
  }

  let currentUser: User | null = getUserLocal();

  if (currentUser) {
    i18next.changeLanguage((currentUser as any).language);
    i18next.changeLanguage((currentUser as any).language);
    setComponentsCulture((currentUser as any).language);
  }

  useEffect(() => {
    const user = getUserLocal();
    !user ?? navigate(ROUTE.LOGIN)
  }, [navigate]);

  let onSuccessfulLogout = () => {
    try{
      TokenService.removeUser();
    } catch (error){
      localStorage.removeItem("user");
    }
    i18next.changeLanguage(defaultLanguage);
    i18next.changeLanguage(defaultLanguage);
    setComponentsCulture((currentUser as any).language);
    navigate(ROUTE.LOGIN);
  };

  const getSettingsItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Settings", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.SETTINGS);
      },
      style: { borderRadius: "3px", marginRight: "10px" }
    };
    return mi;
  };

  const getGeneralDataItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("GeneralData", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.GENERAL);
      },
      style: { borderRadius: "3px", marginRight: "10px" }
    };
    return mi;
  };

  const getAppointmentsItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Appointments", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.APPOINTMENTS);
      },
      style: { borderRadius: "3px", marginRight: "10px" }
    };
    return mi;
  };

  const getWorkPlanDataItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Workplan", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.WORKPLAN);
      },
      style: { borderRadius: "3px", marginRight: "10px" }
    };
    return mi;
  };

  const getDashboardItemMenu = (): ItemMenu => {
    const mi: ItemMenu = {
      label: i18next.t("Dashboards", { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
      icon: "",
      command: () => {
        navigate(ROUTE.DASHBOARDS);
      },
      style: { borderRadius: "3px", marginRight: "10px"},
      
    };
    return mi;
  };

  const getMenu = (): ItemMenu[] => {
    if (!currentUser)
      return [];

    let menu: ItemMenu[] = [];
    menu.push(getSettingsItemMenu());
    menuList['settings'] = menu.length - 1;
    menu.push(getGeneralDataItemMenu());
    menuList['general'] = menu.length - 1;
    menu.push(getAppointmentsItemMenu());
    menuList['appointments'] = menu.length - 1;
    menu.push(getWorkPlanDataItemMenu());
    menuList['workplan'] = menu.length - 1;
    menu.push(getDashboardItemMenu());
    menuList['dashboard'] = menu.length - 1;

    // if (currentUser?.roles.includes(ROLE_ADMIN)){
    //   menu.push(getAdministrationMenuItem());
    //   menuList['administration']=menu.length-1;
    // }

    return menu;
  }

  const getPage = () => {
    const pathName = location.pathname;
    let activeItem: number = 0;

    pathName === "/general" ? activeItem = menuList['general'] :
    pathName === "/appointments" ? activeItem = menuList['appointments'] :
    pathName === "/settings" ? activeItem = menuList['settings'] :
    pathName === "/workplan" ? activeItem = menuList['workplan'] :
      activeItem = 0;

    return activeItem
  }

  const pjson = require('../package.json');
  const version = pjson.version;

  return (

    <div className="App">
      <Header
        menuItems={getMenu()}
        authService={authService}
        hideMenuUserBar={false}
        user={currentUser ? currentUser.name + " " + currentUser.surname : ""}
        menu={currentUser ? MenuType.Home : MenuType.Login}
        onSuccessfulLogout={onSuccessfulLogout}
        activeItem={getPage()}
        productName={"Smart Helper Appoinment"}
        productVersion={version}
        userManualUrl="/documents/user-manual.pdf"
        culture={currentUser ? currentUser.language : 'en-US'}
      />
      {
        currentUser ? <HeaderCurrentPage /> : null
      }

      <Routes>
        <Route path="" element={<PageHome />} />
        <Route path={ROUTE.LOGIN} element={<PageLogin />} />
        <Route path={ROUTE.SETTINGS} element={<PageSettings />} />
        <Route path={ROUTE.GENERAL} element={<PageGeneralData />} />
        <Route path={ROUTE.APPOINTMENTS} element={<PageAppointments />} />
        <Route path={ROUTE.WORKPLAN} element={<PageWorkPlan culture={currentUser ? currentUser.language : 'en-US'}/>} />
      </Routes>
    </div>
  );
}

export default App;
