import { Configuration, IConfigurationService, ICustomService, ConfigurationService, ConfigurationServiceSourceType, TokenService, User, CustomService, CUSTOM_PRJ_TRANSLATION_FILE, ICustomServiceResponse} from "@lcs/frontend";
import React, { useRef, useState } from "react";
import api from '../api';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import i18next from "i18next";
import ProblemService, { AppointmentProblem, DefinedProblemResponse, IProblem, SubmitRequest } from "../services/problem.service";
import { Toast } from "primereact/toast";
import AppointmentService, { AppointmentOut, AppointmentServiceResponse, Plan } from "../services/appointment.service";
import {useInterval} from "../hooks/useInterval"
import { calculateAppointmentEndDate } from "../utils/Common";


function PageAppointments () {
    const POLLING_PLANNING_INTERVAL = 5000;  
    const PROBLEM_PLANNED_STATUS_COMPLETED = "COMPLETED";

    const [submittingProblem, setSubmittingProblem] = useState<boolean>(false);
    
    const configurationService : IConfigurationService = new ConfigurationService({
        entityDefinitionSource: ConfigurationServiceSourceType.axios,
        axios: api,
        entityDefinitionJsonFilePath: null,
        entityRoleJsonFilePath: null
      });
    const customService: ICustomService = new CustomService(api);
    const getUserLocal = () : User | null => {
      var currentUser: User | null = {
        id: 0,
        name: "",
        surname: "",
        language: "",
        roles: []
      }
      try {
        currentUser = TokenService.getUser()
      } catch (error) {
        
      }
      return currentUser;
    }
    let currentUser: User | null = getUserLocal();

    const myToast = useRef<any>();

    const problemService: ProblemService = new ProblemService(api);
    const appointmentService: AppointmentService = new AppointmentService(api);

    useInterval(async () => {
      appointmentService.getMostRecentPlan().then((plan: Plan) => {
        if (plan.stato === PROBLEM_PLANNED_STATUS_COMPLETED){
          if (submittingProblem){
            setSubmittingProblem(false);
            myToast.current.show({
              severity: 'success',
              summary: i18next.t('PlanningCompleted', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
              detail: "OK"
            });
          }
        }
        else
          setSubmittingProblem(true);
      });
    }, POLLING_PLANNING_INTERVAL)

    const checkStartDate = (): Promise<boolean> => {
      let checkResult: boolean = false;
      let probStartDate: Date = new Date();
      return new Promise<boolean>((resolve, reject) =>{
        // 1 - get start date of current problem
        problemService.getDefinedProblem().then((probResp: DefinedProblemResponse) => {
          probStartDate = new Date(probResp.data[0].startDate);        
        })
        .then(() => { // 2 - get closed appointments 
          let maxEndDate: Date | undefined = undefined;       
          appointmentService.getAllClosed().then((res:AppointmentServiceResponse) => {
            if (res.success && res.data.length > 0){                                                
                res.data.forEach((appuntamento: AppointmentOut) => {
                  const eventStart: Date = appuntamento.orarioPianificato? new Date(appuntamento.orarioPianificato):new Date();
                  const endDate: Date = calculateAppointmentEndDate(eventStart, appuntamento.durataStimataInMezzore);
                  if (maxEndDate && (maxEndDate >=  endDate)){
                    return;
                  } else {
                    maxEndDate = endDate;
                  }                                
                });
                // 3 - return if there are closed appointements which ends after the start date.                             
                if (maxEndDate && (maxEndDate <= probStartDate))
                  checkResult = true;
                resolve(checkResult);
            } else if (res.success && res.data.length === 0){
              resolve(true);
            }            
            else{
                myToast.current.show({
                    severity: 'error',
                    summary: i18next.t('Error', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
                    detail: res.reason // TODO: translate
                  });
                  reject(res.reason);
            }
          }).catch((reason: any) => {
            myToast.current.show({
              severity: 'error',
              summary: i18next.t('Error'),
              detail: 'Generic error on getting closed appointments' // TODO: translate
            });
            console.log(reason.message);    
            reject('Generic error on getting closed appointments');     
          });                 
        })
        .catch((reason: any) => {
          myToast.current.show({
            severity: 'error',
            summary: i18next.t('Error'),
            detail: 'Generic error on getting defined problem data' // TODO: translate
          });
          console.log(reason.message);  
          reject('Generic error on getting defined problem data');      
        });
        
      });      
    }



    const ExportAndSubmitProlem = () => {
      const myProb: IProblem = {
        id: null
      }
      problemService.export(myProb)
      .then((appProb:AppointmentProblem) => {
        if (appProb){          
          const subReq :SubmitRequest = {
            problemName: "Problema Unico",
            problemDescription: "APPOINTMENT_MANAGEMENT",
            rulesConfig: undefined,
            problem: JSON.stringify(appProb),
            terminationConfig: undefined
          }
          problemService.submit(subReq).then((result: ICustomServiceResponse) => {
            if (result.success) {
              myToast.current.show({
                severity: 'success',
                summary: i18next.t('PlanningStarted', { ns: CUSTOM_PRJ_TRANSLATION_FILE }), // Pianificatore attivato
                detail: result.reason
              });
              // TODO: refresh data -- library
            }
            else{
              myToast.current.show({
                severity: 'error',
                summary: i18next.t('Error'),
                detail: result.reason
              });
            }                                   
          });
        }
      })
      .catch((reason: any) => {
        myToast.current.show({
          severity: 'error',
          summary: i18next.t('Error'),
          detail: 'Generic error on exporting problem data'
        });
        console.log(reason.message);
        setSubmittingProblem(false);
      });
    }

    const accept = () => {
      ExportAndSubmitProlem();
    }

    const reject = () => {
      myToast.current.show({ severity: 'warn', summary: i18next.t('Cancelled', { ns: CUSTOM_PRJ_TRANSLATION_FILE }), detail: i18next.t('CancelledPlanning', { ns: CUSTOM_PRJ_TRANSLATION_FILE }), life: 3000 });
    }

    const submit = (): void => {
      setSubmittingProblem(true);
      checkStartDate()
      .then(
        (checkRes: boolean) => {
        if (!checkRes){
          confirmDialog({
            message: i18next.t('PlanningStartDateWarning', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
            header: i18next.t('PlanningStartDateWarningHeader', { ns: CUSTOM_PRJ_TRANSLATION_FILE }),
            icon: 'pi pi-exclamation-triangle',
            accept,
            reject
          });
          setSubmittingProblem(false);
          return;
        } else {          
          ExportAndSubmitProlem();
        }
      });
    }

    return (
      <div style={{margin:"5px"}}>
      <ConfirmDialog />
      <Toast ref={myToast} />      
      <Button 
        label={i18next.t("Submit", { ns: CUSTOM_PRJ_TRANSLATION_FILE })} 
        icon="pi pi-play"
        style={{marginLeft: "0px !important"}}
        onClick={submit}
        loading={submittingProblem}
        />
      <Configuration
        configurationService={configurationService}
        customService={customService}
        instance={"Appuntamenti"}
        colorHeaderPdf="#aa0b0a"
        culture={currentUser ? currentUser.language : "en-US"}
        filterHeader=""
        refreshButtons={true}
      />
      </div>
    );          
}

export default PageAppointments;