import React, { useState } from "react";
import i18next from "i18next";
import { COMMON_TRANSLATION_FILE, CUSTOM_PRJ_TRANSLATION_FILE } from "@lcs/frontend";
import { Button } from "primereact/button";
import { TabView, TabPanel } from 'primereact/tabview';
import { APPOINTMENT_STATE } from "../services/appointment.service";
import { EventExtendedProps } from "../pages/PageWorkPlan";
import AppointmentProperty from "./AppointmentProperty";

export interface AppointmentDetailProps{
    eventDetails: EventExtendedProps;
    onCloseApp: () => void;
    onConfirmApp: () => void;
    confirmingAppointment: boolean;
    closingAppointment: boolean;
}

const AppointmentDetail = (props: AppointmentDetailProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        

<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
    <TabPanel header="Info">
    <div className="flex-container" 
             style={{display: "flex", flexDirection: "column", flexWrap: "wrap"}}>
            <AppointmentProperty
                label="Cliente"
                content={props.eventDetails.cliente}
            />
            <AppointmentProperty
                label="Specializzazione"
                content={props.eventDetails.specializzazione}
            />            
            <AppointmentProperty
                label="Durata"
                content={props.eventDetails.durata}
            />          
            <AppointmentProperty
                label="Inizio"
                content={props.eventDetails.inizio?.toLocaleString()}
            />
            <AppointmentProperty
                label="Status"
                content={props.eventDetails.stato?
                    (props.eventDetails.stato === APPOINTMENT_STATE.PIANIFICATO && props.eventDetails.confermato?
                        i18next.t("CONFERMATO", { ns: CUSTOM_PRJ_TRANSLATION_FILE })
                        : i18next.t(props.eventDetails.stato, { ns: CUSTOM_PRJ_TRANSLATION_FILE }))
                    : ""
                }
            />  
            <AppointmentProperty
                label="LuogoAppuntamento"
                content={i18next.t(props.eventDetails.luogoAppuntamento??"", { ns: CUSTOM_PRJ_TRANSLATION_FILE })}
            />          
            <AppointmentProperty
                label="Indirizzo"
                content={props.eventDetails.indirizzoPianificato + " " +
                            props.eventDetails.cittaPianificata + " " +
                            props.eventDetails.provinciaPianificata + " " 
                }
            /> 
            
            <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap",
                        marginTop: "5px", justifyContent: "center"}}>
            <Button style={{margin:"5px", width:"200px"}}
                        label={i18next.t("ConfermaAppuntamento", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}
                        onClick={props.onConfirmApp}
                        disabled={props.eventDetails.confermato}
                        loading={props.confirmingAppointment}
            />
            <Button style={{margin:"5px", width:"200px"}}
                    label={i18next.t("ChiudiAppuntamento", { ns: [CUSTOM_PRJ_TRANSLATION_FILE, COMMON_TRANSLATION_FILE] })}
                    onClick={props.onCloseApp}
                    disabled={props.eventDetails.stato !== APPOINTMENT_STATE.PIANIFICATO}
                    loading={props.closingAppointment}
            />
            </div>
        </div>      
    </TabPanel>
    <TabPanel header="Note">
        <AppointmentProperty
            label="Note"
            content={props.eventDetails.note}
        />  
    </TabPanel>    
</TabView>
           
    );
}

export default AppointmentDetail;