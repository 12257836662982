import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { CUSTOM_PRJ_TRANSLATION_FILE} from '@lcs/frontend';
import { locales_it_common } from '@lcs/frontend';
import { locales_en_common } from '@lcs/frontend';
import locales_it_sh_appointments from './locales/it/sh_appointments.json';
import locales_en_sh_appointments from './locales/en/sh_appointments.json';

export const defaultLanguage : string = "it-IT";

export const initializeI18n = () : void => {
    i18n
    .use(initReactI18next)
    .init({
      resources:{
        it: {
          common: locales_it_common,
          custom: locales_it_sh_appointments
        },
        en: {
          common: locales_en_common,
          custom: locales_en_sh_appointments
        } 
      },
      debug: true,
      load: 'languageOnly',
      fallbackLng: defaultLanguage,
      ns: ['common', CUSTOM_PRJ_TRANSLATION_FILE],
      defaultNS: 'common',
    });

}