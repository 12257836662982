import { Fragment, useState } from "react";
import { handleError } from "./utils/Common";
import HeaderUserComp, { MenuType } from "./components/HeaderUserComp/HeaderUserComp";
import {
  IAuthService,
  LogoutRequest,
  LogoutResponse,
} from "./services/auth.service";
import TokenService from "./services/token.service";
import { readConfigFile } from "typescript";
import { TabMenu } from "primereact/tabmenu";

export interface HeaderProps {
  menuItems: ItemMenu[];
  menu: MenuType;
  user: string | null;
  culture?: string;
  hideMenuUserBar: boolean;
  onSuccessfulLogout: any;
  authService: IAuthService;
  activeItem: number;
  productName: string;
  productVersion: string;
  userManualUrl?: string;
}

export interface ItemMenu {
  label: string;
  icon: string;
  command: any;
  style: React.CSSProperties;
}

function Header(props: HeaderProps) {
  const [activeIndex, setActiveIndex] = useState(props.activeItem);
  var menuItems = props.menuItems;
  async function logOut() {
    const user = TokenService.getUser();

    if (!user) {
      handleError("LOGOUT", "Error"); //TODO Gestire meglio
      return;
    }

    const request: LogoutRequest = {
      userId: user.id,
    };
    let response: LogoutResponse | void = await props.authService
      .logout(request)
      .catch(function (error: any) {
        handleError("LOGOUT", error);
      });

    props.onSuccessfulLogout();

    if (response && !response.success) {
      console.log(response.reason); //TODO AUS: controllare questo caso
      let error = {
        message: response.reason,
      };
      handleError("LOGOUT", error);
    }
  }

  function getHeader() {
    return (
      <div
        className="header"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <img
          src="/images/logo.png"
          style={{ borderRadius: "4px", marginRight: "5px" }}
          alt=""
          className="p-img-menubar"
        />
        <div className="header-tab">
          <TabMenu
            className="newMenu"
            model={props.menuItems}
            id="popup_menu"
            activeIndex={props.activeItem}
            onTabChange={(e) => setActiveIndex(e.index)}
          />
        </div>
        <div
          className="header-user"
          style={{
            alignItems: "stretch",
            flexDirection: "column",
            display: "flex",
            flexWrap: "wrap",
            float: "right",
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <HeaderUserComp
            authService={props.authService}
            user={props.user}
            menu={props.menu}
            logOutHandler={logOut}
            productName={props.productName}
            productVersion={props.productVersion}
            userManualUrl={props.userManualUrl}
            culture={props.culture}
          />
        </div>
      </div>
    );
  }

  function showView() {
    return <Fragment>{getHeader()}</Fragment>;
  }

  return showView();
}

export default Header;
