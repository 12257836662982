import { ChangePasswordRequest, ChangePasswordResponse, IAuthService, LoginRequest, LoginResponse, LogoutRequest, LogoutResponse, RefreshTokenRequest, RefreshTokenResponse, TokenService, User } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";

export interface SmartHelperLoginResponse {
    success: boolean;
    reason: string;
    token: string;	
}

export interface SmartHelperLoginRequest {
    username: string;
    password: string;
    timeZoneId?: string;
}

class SmartHelperAuthService implements IAuthService {
    readonly axios : AxiosInstance;

    LOGIN: string;
    LOGOUT: string;
    GETUSERINFO: string;
    CHANGE_PASSWORD: string;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
        const pjson = require('../../package.json');
        let AUTHENTICATION_BASE_PATH = pjson.com_lcs.authServer;
        this.LOGOUT = AUTHENTICATION_BASE_PATH + '/logout';
        this.LOGIN = AUTHENTICATION_BASE_PATH + '/login';
        this.GETUSERINFO = AUTHENTICATION_BASE_PATH + '/getUserInfo';
        this.CHANGE_PASSWORD = AUTHENTICATION_BASE_PATH + '/changePassword';
    }

    login = (request: LoginRequest) : Promise<LoginResponse> => {
        let shLoginReq : SmartHelperLoginRequest = request;
        shLoginReq.timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.axios
            .post(this.LOGIN, shLoginReq)
            .then((response : AxiosResponse<SmartHelperLoginResponse>) => {
                if ((response && response.data.success) ||
                (response && !response.data.success && response.data.reason === "ALREADY LOGGED IN")){
                    // this.axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
                    // this.axios.defaults.headers.common["Content-Type"] = "application/json";
                    var user: User = {
                        id: 0,
                        name: "",
                        surname: "",
                        language: "",
                        roles: []
                    }
                    TokenService.setUser(user);
                    TokenService.updateLocalAccessToken(response.data.token);
                    return this.getuserinfoCall().then((userInfo: LoginResponse) => {
                        if (userInfo.success) userInfo.accessToken = response.data.token;
                        return userInfo;
                    })
                }
                else
                    return this.getEmptyLoginResponse();                                
        });            
    }

    getEmptyLoginResponse = (): LoginResponse => {
        return {
            success: false,
                reason: "",
                accessToken: "",
                id: 0,
                refreshToken: "",
                email: "",
                tokenType: "Bearer",
                username: "",
                name: "",
                surname: "",
                language: "",
                roles: []
        };
    } 

    getuserinfoCall = async () => {
        let loginUserInfo: LoginResponse = this.getEmptyLoginResponse();
        return this.axios
            .post(this.GETUSERINFO)
            .then((resUserInfo : AxiosResponse<any>): LoginResponse  => {
                if (resUserInfo && resUserInfo.data.success) {
                    loginUserInfo = {
                        success: true,
                        reason: "OK",
                        accessToken: "",
                        id: 0,
                        refreshToken: "",
                        email: "",
                        tokenType: "Bearer",
                        username: "",
                        name: resUserInfo.data.userinfo.name,
                        surname: resUserInfo.data.userinfo.surname,
                        language: resUserInfo.data.userinfo.language,
                        roles: [resUserInfo.data.userinfo.modeltypelist.filter((x: any) => x.modeltypename === "APPOINTMENT_MANAGEMENT").usergroup]
                    };                    
                }
                else {
                    if (resUserInfo && !resUserInfo.data.success) {
                        console.log(resUserInfo.data.reason); //TODO AUS: controllare questo caso
                    }
                }
                return loginUserInfo;
            });                        
    }
    
    logout = (request: LogoutRequest) : Promise<LogoutResponse> => {
        return this.axios
            .post(this.LOGOUT, request)
            .then((result : AxiosResponse<LogoutResponse>) => result.data);
    }

    refreshToken(request: RefreshTokenRequest): Promise<RefreshTokenResponse> {
        throw new Error("Method not implemented.");
    }

    updatePassword(request: ChangePasswordRequest): Promise<ChangePasswordResponse> {
        return this.axios
            .post(this.CHANGE_PASSWORD, request)
            .then((result : AxiosResponse<ChangePasswordResponse>) => result.data);
    }

/*     refreshToken = (request: RefreshTokenRequest) : Promise<RefreshTokenResponse> => {
        return this.axios
            .post("/api/authentication/refreshToken", request)
            .then((result : AxiosResponse<RefreshTokenResponse>) => result.data);
    } */
}

export default SmartHelperAuthService;