import axios, { AxiosInstance } from "axios";

const BASE_PATH = ''

const instance : AxiosInstance = axios.create({
  baseURL: BASE_PATH,
  headers: {
    Content_Type: "application/json",
  },
});

export default instance;