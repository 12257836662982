import { ICustomServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";


export interface IStartDate {
    value: string;
}
export interface IProblem {
    id: number | null;
    denominazione?: string;
    nome?: string;
    idAzienda?: number;	
    startDate?: string;
}

export interface AppointmentProblem {
    dipendenti: Dipendente[];
    appuntamenti: any;    	
    startDate: IStartDate;
    score: any;
    scores: any;
}

export interface DefinedProblem {
    id: number;
    denominazione: string;
    nome: string;   
    idAzienda: LinkedResource;	
    startDate: Date;  
    planningDays: number;  
}

export interface DefinedProblemResponse extends ICustomServiceResponse{
    data: DefinedProblem[];
}

export interface SubmitRequest {
    problemName: string; // "Appuntamento" (di fantasia)
	problemDescription: string; // "APPOINTMENT_MANAGEMENT" (fisso)
	rulesConfig: any; // null
	problem: string;  // stringify AppointmentProblem (da export)
	terminationConfig: any; // null
}

export interface LastSubmittedRequest {
    problemName: string;
}

export interface QuerySolutionRequest{
    problemId: number,
    transformArgs: any // null
}

export interface QuerySolutionResponse extends ICustomServiceResponse{
    problemId: number,
	finished: boolean,
	solution: string,
	problemClassName: string,
	problemName: string,
	submitDate: Date,
	solveDate: Date
}

export interface ProblemStatus {
    problemId: number,
	problemName?: string,
	finished?: boolean,
	monitoring?: boolean,
	submitDate?: Date,
	solveDate?: Date
}

export interface LastSubmittedResponse extends ICustomServiceResponse{
    problemStatus: ProblemStatus;
}

export interface NextAppuntamento{
    nextAppuntamento: NextAppuntamento | undefined;
    entityId: number;
    durataStimataInMezzore: number,
    mezzoraDInizio: number,
    cliente: string,
    tipoCliente?: TipoCliente,
    dipendente?: NomeCognome,
    sede?: Sede,
    argomento?: Argomento
}

export interface Argomento{
    descrizione: string
}

export interface TipoCliente{
    descrizione: string
}

export interface NomeCognome {
    nome: string;
    cognome: string;
}

export interface Sede{
    citta: string
}

export interface Dipendente{
    entityId: number,
    mezzoraDiFine: number,
    nextAppuntamento: NextAppuntamento,
    nomeCognome: NomeCognome,
    notWorkingRanges: any[],
    sede: Sede,
    valutazioni: any[]
}

class ProblemService {
    readonly axios : AxiosInstance;
    SUBMIT: string;
    QUERY_SOL: string;
    LAST_SUBMITTED: string;

    constructor(axios: AxiosInstance) {
        this.axios = axios; // con base path sull'AppointmentService

        const pjson = require('../../package.json');
        let PLANNING_BASE_PATH = pjson.com_lcs.planningServer;
        this.SUBMIT = PLANNING_BASE_PATH + '/submit';
        this.QUERY_SOL = PLANNING_BASE_PATH + '/querySolution';
        this.LAST_SUBMITTED = PLANNING_BASE_PATH + '/getLastSubmitted';
    }

    getDefinedProblem = () : Promise<DefinedProblemResponse> => {
        return this.axios
            .get("/api/data/problema")
            .then((result : AxiosResponse<DefinedProblemResponse>) => result.data);
    }

    export = (problem: IProblem) : Promise<AppointmentProblem> => {
        return this.axios
            .post("/api/behavior/exportProblema", problem)
            .then((result : AxiosResponse<AppointmentProblem>) => result.data);
    }

    submit = (submitReq: SubmitRequest) : Promise<ICustomServiceResponse> => {
        return this.axios
            .post(this.SUBMIT, submitReq)
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }

    getLastSubmittedProblem = (lsRequest: LastSubmittedRequest) : Promise<LastSubmittedResponse> => {
        return this.axios
        .post(this.LAST_SUBMITTED, lsRequest)
        .then((result : AxiosResponse<LastSubmittedResponse>) => result.data);
    }

    querySolution = (qsRequest: QuerySolutionRequest) : Promise<QuerySolutionResponse> => {
        return this.axios
            .post(this.QUERY_SOL, qsRequest)
            .then((result : AxiosResponse<QuerySolutionResponse>) => result.data);
    }

}

export default ProblemService;