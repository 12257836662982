import { ICustomServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";

export interface AppointmentIn{
    id: number;  
    idDipendenteStabilito?: number | LinkedResource;
    orarioStabilito?: string;
}

export enum APPOINTMENT_LOCATION {
    REMOTO,				// Nessun vincolo
	PRESSO_DIPENDENTE,	// 
	PRESSO_CLIENTE,
	ALTRA_SEDE
}

export interface AppointmentOut extends AppointmentIn{ 
    idDipendentePianificato?: LinkedResource;
    orarioPianificato?: string;        
    idCliente?: LinkedResource;
    denominazione?: string;
    durataStimataInMezzore?: string;
    idAzienda?: LinkedResource;
    idDipendenteEscluso?:LinkedResource;
    idDipendentePreferito?:LinkedResource;
    idProblema?:LinkedResource;
    luogoAppuntamento?:APPOINTMENT_LOCATION;
    indirizzo?: string;
    idSpecializzazione?:LinkedResource;
    idTipoCliente?:LinkedResource;
    orarioPreferito?: string;
    stato?: APPOINTMENT_STATE;
    citta?: string;
    provincia?: string;
    indirizzoPianificato?: string;
    cittaPianificata?: string;
    provinciaPianificata?: string;
    note?: string;
}

export enum APPOINTMENT_STATE{
    PIANIFICATO = "PIANIFICATO",
    CHIUSO_INTERLOCUTORIO = "CHIUSO_INTERLOCUTORIO",
    CHIUSO_FALLIMENTO = "CHIUSO_FALLIMENTO",
    CHIUSO_SUCCESSO = "CHIUSO_SUCCESSO"
}

export interface AppointmentServiceResponse extends ICustomServiceResponse {
    data: AppointmentOut[]
}

export interface AppointmentServiceSingleResponse extends ICustomServiceResponse {
    data: AppointmentOut;
}

export interface Plan {
    id: number;
    denominazione: string;
    idProblema: number;
    exportDate: Date;
    submitDate: Date;
    solutionDate: Date;
    completionDate: Date;
    problemJson: string;
    solutionJson: string;
    stato: string; // EXPORTED,SUBMITTED,SOLVED,COMPLETED
}

export interface CloseAppointmentRequest{
    appointmentId: number,
    status: APPOINTMENT_STATE,
    note: string
}

class AppointmentService {
    readonly axios : AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    getAllClosed = () : Promise<AppointmentServiceResponse> => {
        return this.axios
            .get("/api/data/appuntamento/chiuso/")
            .then((result : AxiosResponse<AppointmentServiceResponse>) => result.data);
    }

    getAll = () : Promise<AppointmentServiceResponse> => {
        return this.axios
            .get("/api/data/appuntamento/")
            .then((result : AxiosResponse<AppointmentServiceResponse>) => result.data);
    }

    getById = (appointmentId: string) : Promise<AppointmentServiceSingleResponse> => {
        return this.axios
            .get("/api/data/appuntamento/" + appointmentId)
            .then((result : AxiosResponse<AppointmentServiceSingleResponse>) => result.data);
    }

    comfirm = (appointment: AppointmentIn) : Promise<ICustomServiceResponse> => {
        const params = new URLSearchParams([['itemId', appointment.id?.toString()??'']]);
        return this.axios
            .put("/api/data/appuntamento", appointment, {params})
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }

    close = (appointment: CloseAppointmentRequest) : Promise<ICustomServiceResponse> => {        
        return this.axios
            .post("/api/behavior/closeAppuntamento", appointment)
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }

    getMostRecentPlan = () : Promise<Plan> => {
        return this.axios
            .get("/api/data/pianificazione/mostrecent")
            .then((result : AxiosResponse<Plan>) => result.data);
    }
}

export default AppointmentService;