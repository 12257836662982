import { IAuthService, Login, TokenService, User, setComponentsCulture } from "@lcs/frontend";
import React from "react";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

import api from "../api"
import { ROLE_ADMIN, ROUTE } from "../utils/Constants";
import SmartHelperAuthService from "../services/smarthelper.auth.service";

function PageLogin () {
    const authService : IAuthService = new SmartHelperAuthService(api);
    const navigate = useNavigate();

  
    let onSuccessfulLogin = (user: User) => {

        TokenService.setUser(user);
        i18next.changeLanguage(user.language);
        setComponentsCulture(user.language);

        if (user.roles.includes(ROLE_ADMIN))
            navigate(ROUTE.ADMINISTRATION);
        else
            navigate(ROUTE.GENERAL);
     };
   
    return (
        <Login onSuccessfulLogin={onSuccessfulLogin} authService={authService}/>        
    );
}

export default PageLogin;