import { ICustomServiceResponse } from "@lcs/frontend";
import { AxiosInstance, AxiosResponse } from "axios";
import { LinkedResource } from "./common.types";


export interface EmployeeOut{     
    id: number;
    cognome: string;
    nome: string;   
    denominazione: string;
    idAzienda?: LinkedResource;    
    idProblema?:LinkedResource;
    idSede?:LinkedResource; 
    idOrarioGiornaliero?:LinkedResource;     
}

export interface EmployeeServiceResponse extends ICustomServiceResponse {
    data: EmployeeOut[]
}

class EmployeeService {
    readonly axios : AxiosInstance;

    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }

    getAll = () : Promise<EmployeeServiceResponse> => {
        return this.axios
            .get("/api/data/dipendente/")
            .then((result : AxiosResponse<EmployeeServiceResponse>) => result.data);
    }

    getById = (employeeId: string) : Promise<ICustomServiceResponse> => {
        return this.axios
            .get("/api/data/dipendente/" + employeeId)
            .then((result : AxiosResponse<ICustomServiceResponse>) => result.data);
    }

    getAllMyCollegues = () : Promise<EmployeeServiceResponse> => {
        return this.axios
            .get("/api/data/dipendente/listAllMyCollegues")
            .then((result : AxiosResponse<EmployeeServiceResponse>) => result.data);
    }

}

export default EmployeeService;